<template>
    <div>
        <div class="mt-3 fl-eq">
            <div class="card shadow">
                <h6>Sale_1</h6>
            </div>
            <div class="card shadow">
                <h6>Sale_2</h6>
            </div>
            <div class="card shadow">
                <h6>Sale_13</h6>
            </div>
            <div class="card shadow">
                <h6>Sale_4</h6>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : 'salesCard'
};
</script>

<style scoped>
.shadow {
    box-shadow: 0 4px 24px 0 rgb(0 0 0 / 20%);
}
</style>

<template>
<div>
    <div class="mb-3">
        <sales-card></sales-card>
    </div>
    <div class="fl-eq">
        <analytics-component-one></analytics-component-one>
        <analytics-component-two></analytics-component-two>

    </div>
</div>
</template>

<script>
import SalesCard from '@/views/dashboard/dashboardComponents/salesCard';
import AnalyticsComponentOne from '@/views/dashboard/dashboardComponents/analyticsComponentOne';
import AnalyticsComponentTwo from '@/views/dashboard/dashboardComponents/analyticsComponentTwo';
export default {
    name       : 'dashboard',
    components : { AnalyticsComponentTwo, AnalyticsComponentOne, SalesCard }
};
</script>

<style scoped>

</style>
